<template>
    <div class="container">
        <footer class="py-3 my-4">
            <!-- <ul class="nav justify-content-center border-bottom pb-3 mb-3">
                <li class="nav-item"><router-link to="/" href="#" class="nav-link px-2 text-muted">Home</router-link></li>
                 <li class="nav-item"><router-link to="/categorias" href="#" class="nav-link px-2 text-muted">Categorias</router-link></li>
                <li class="nav-item"><a href="mailto:biblioteca10@uninove.br" class="nav-link px-2 text-muted">Ajuda</a></li>
            </ul> -->
            <!-- <p class="text-center text-muted">© 2022 UNINOVE - Biblioteca UNINOVE</p> -->
            <p class="text-center text-muted">©2022 UNINOVE - Colégio Técnico</p>
        </footer>
    </div>
</template>

<script>
export default {
  name: 'rodape'
}
</script>
