import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style of swiper
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/emProducao.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/home2',
    name: 'Home2',
    component: () => import(/* webpackChunkName: "home2" */ '../views/home/Old-Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/testes/About.vue')
  },
  {
    path: '/categorias',
    name: 'categorias',
    component: () => import(/* webpackChunkName: "categorias" */ '../views/categorias.vue')
  },
  // {
  //   path: '/login2',
  //   name: 'login2',
  //   component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
  // },
  // Páginas admin
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/admin.vue')
  },
  {
    path: '/admin/editorTags',
    name: 'admin-editorTags',
    component: () => import(/* webpackChunkName: "admin-edito" */ '../views/admin/editorTags.vue')
  }, 
  {
    path: '/admin/top-lidos',
    name: 'adminTopLidos',
    component: () => import(/* webpackChunkName: "adminTopLidos" */ '../views/admin/adminTopLidos.vue')
  }, 
  {
    path: '/areas',
    name: 'areas-conhecimento',
    component: () => import(/* webpackChunkName: "areas-conhecimento" */ '../views/testes/areasTeste.vue')
  },
  {
    path: '/dev/admin',
    name: 'administrador',
    component: () => import(/* webpackChunkName: "administrador" */ '../views/teste_nadir/administrador.vue')
  },
  {
    path: '/cadastro_geral',
    name: 'cadastro_geral',
    component: () => import(/* webpackChunkName: "nadir-cadastro_geral" */ '../views/teste_nadir/cadastro_geral.vue')
  },
  {
    path: '/test',
    name: 'text_test',
    component: () => import(/* webpackChunkName: "nadir-text_test" */ '../views/testes/text_test.vue')
  },
  {
    path: '/test2',
    name: 'text_test2',
    component: () => import(/* webpackChunkName: "nadir-text_test2" */ '../views/testes/area_executar.vue')
  },
  {
    path: '/latex',
    name: 'text_test3',
    component: () => import(/* webpackChunkName: "nadir-latext" */ '../views/testes/latex/index.vue')
  },
  {
    path: '/latex2',
    name: 'latex',
    component: () => import(/* webpackChunkName: "nadir-latext2" */ '../views/testes/latex/index2.vue')
  },
  {
    path: '/latex3',
    name: 'latex_exporter',
    component: () => import(/* webpackChunkName: "nadir-latex_exporter" */ '../views/testes/latex/latex_exporter.vue')
  },
  {
    path: '/geral_exporter',
    name: 'geral_exporter',
    component: () => import(/* webpackChunkName: "nadir-geral_exporter" */ '../views/testes/geral/geral_exporter.vue')
  },
  {
    path: '/aceEditor',
    name: 'aceEditor',
    component: () => import(/* webpackChunkName: "nadir-aceEditor" */ '../views/testes/aceEditor.vue')
  },
  {
    path: '/curso=:id_firebase/campus=:id_campus',
    name: 'final_exporter',
    component: () => import(/* webpackChunkName: "nadir-final_exporter" */ '../views/testes/latex/latex_exporter.vue')
  },
  {
    path: '/cadastro',
    name: 'teste_nadir_visualizar',
    component: () => import(/* webpackChunkName: "teste_nadir_visualizar" */ '../views/teste_nadir/form_esp_conteudista.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/teste_nadir/login.vue')
  },
  {
    path: '/doc_completo=:doc',
    name: 'teste_nadir_doc_completo',
    component: () => import(/* webpackChunkName: "teste_nadir_doc_completo" */ '../views/teste_nadir/doc_completo.vue')
  },
  {
    path: '/edit=:id_firebase',
    name: 'form_esp_conteudista',
    component: () => import(/* webpackChunkName: "form_esp_conteudista" */ '../views/teste_nadir/form_esp_conteudista.vue')
  },
  {
    path: '/do=:id_firebase',
    name: 'conteudista_teste',
    component: () => import(/* webpackChunkName: "conteudista_teste" */ '../views/teste_nadir/dois.vue')
  },
  {
    path: '/uninove_edit=:id_firebase',
    name: 'form_esp_uninove',
    component: () => import(/* webpackChunkName: "form_esp_uninove" */ '../views/teste_nadir/form_esp_uninove.vue')
  },
  {
    path: '/campus=:id_campus',
    name: 'forms_campus_esp',
    component: () => import(/* webpackChunkName: "forms_campus_esp" */ '../views/teste_nadir/forms_campus_esp.vue')
  },
  {
    path: '/lab/uninove_edit=:id_firebase',
    name: 'form_esp_uninove_lab',
    component: () => import(/* webpackChunkName: "form_esp_uninove_lab" */ '../views/teste_nadir/form_esp_uninove.vue')
  },
  {
    path: '/curso=:curso',
    name: 'Curso.id',
    component: () => import(/* webpackChunkName: "cursos" */ '../views/livrosPorCurso.vue'),
    children: [
        {
            path: 'livros',
            name: 'view.livro',
            components: {
                livros_por_curso_view: () => import(/* webpackChunkName: "menuCursos" */ '../views/livros_por_curso_view/menuLivros.vue'),
            }
        },
        {
            path: 'cursos',
            name: 'view.cursos',
            components: {
                livros_por_curso_view: () => import(/* webpackChunkName: "cursos" */ '../views/livros_por_curso_view/menuCursos.vue'),
            }
        },
        {
            path: 'subcategorias',
            name: 'view.subcategorias',
            components: {
                livros_por_curso_view: () => import(/* webpackChunkName: "categorias" */ '../views/livros_por_curso_view/menuSubCat.vue'),
            }
        },
        {
            path: 'lista-de-leituras',
            name: 'view.menuListaLeitura',
            components: {
                livros_por_curso_view: () => import(/* webpackChunkName: "lista-de-leituras" */ '../views/livros_por_curso_view/menuListaLeitura.vue'),
            }
        },
        {
            path: 'videos',
            name: '',
            components: {
                livros_por_curso_view: () => import(/* webpackChunkName: "videos" */ '../views/livros_por_curso_view/menuVideos.vue'),
            }
        },
    ],
  },
  {
    path: '/teste/curso',
    name: 'teste.curso',
    component: () => import(/* webpackChunkName: "cursos" */ '../views/curso_main.vue')
  },
  {
    path: '/upload',
    name: 'teste.upload_img',
    component: () => import(/* webpackChunkName: "upload_img" */ '../views/teste_nadir/components/upload_img.vue')
  },
  {
    path: '/test/firebaseSandbox',
    name: 'firebaseSandbox',
    component: () => import(/* webpackChunkName: "firebaseSandbox" */ '../views/testes/firebaseSandbox.vue')
  },
  {
    path: '/test/livrosPorCurso/curso=:curso',
    name: 'livrosPorCursoTeste',
    component: () => import(/* webpackChunkName: "livrosPorCursoTeste" */ '../views/testes/livros_por_curso_teste.vue')
  },
  {
    path: '/contato-biblioteca',
    name: 'contato.biblioteca',
    component: () => import(/* webpackChunkName: "contato.biblioteca" */ '../views/contato-biblioteca.vue')
  },
  {
    path: '/em-producao',
    name: 'em-producao',
    component: () => import(/* webpackChunkName: "em-producao" */ '../views/emProducao.vue')
  },
  {
    path: '/teste/apiExlibrisTeste',
    name: 'apiExlibrisTeste',
    component: () => import(/* webpackChunkName: "apiExlibrisTeste" */ '../views/testes/apiExlibrisTeste.vue')
  },
  {
    path: '/med',
    name: 'casos-galeria',
    component: () => import(/* webpackChunkName: "apiExlibrisTeste" */ '../views/med/index.vue'),
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'caso-Clinico',
        component: () => import(/* webpackChunkName: "firebaseSandbox" */ '../views/testes/firebaseSandbox.vue'),
      },
      {
        // UserPosts will be rendered inside User's <router-view>
        // when /user/:id/posts is matched
        path: 'caso-Correcao',
        component: () => import(/* webpackChunkName: "em-producao" */ '../views/emProducao.vue'),
      },
    ],
  },
  
]



const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
  mode: "history"
})

// router.beforeEach((to) => {
//   if(
//     localStorage.name == 'conteudista' &&
//     to.name == '/dev/admin'
//   ) {
//     console.log('deu match')
//     // redirect the user to the login page
//     // return { name: 'login' }
//   } else {
//     return { name: to.name }
//   }
// })

export default router
