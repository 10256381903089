<template>
  <header class="p-3 mb-3 border-bottom">
      <div class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
                <img v-if="mode === 'light'" id="logo" src="https://aluno.uninove.br/img/logo_branco_uninove.png" alt="Logo Image">
                <img v-else id="logo" src="https://www.uninove.br/logo-uninove.svg" alt="Logo Image">
            </a>

            <ul class="nav mx-2 col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                <li><strong>Colégio Técnico | Usuário: {{userName}} ({{perfil}})</strong></li>
            </ul>

            <div class="col" style="text-align: right">
                <b-button class="m-1" @click="logOut()" variant="outline-primary" style="width: fit-content">Sair</b-button>
            </div>


        </div>
      </div>
    </header>
</template>

<script>
// import Toggle from '@/components/Toggle'
// import firebase from "firebase/app";

export default {
    props: {
        mode: String,
    },
    name: 'navbar',
    components: {
        // Toggle
    },
    data() {
        return {
            // imgUsuario: '',
            // userName: ''
        }
    },
    methods: {
        logOut(){
            localStorage.removeItem('name')
            localStorage.removeItem('timestamp')
            localStorage.removeItem('perfil')
            localStorage.clear()
            this.$router.replace({path: "/login"})
        }
    },
    created() {
        this.userName = localStorage.name
        this.perfil = localStorage.perfil
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  header{
    background-color: var(--primary-nav);
    transition: 0.3s ease-in-out;
    border-bottom: 1px solid var(--border-nav)!important;
  }
  a {
    color: var(--fontes) !important;
    font-weight: 700;
  }
  #logo {
    height: 50px;   
  }
  .userNavbar{
    color: red
  }
  .user-image{
    height: 20px;
  }
</style>
