<template>
    <div id="app" style="background-color: #eef4fc">
        <!-- <div class="container-fluid">
            <div class="container">
                <div class="row">
                    <div class="col" style="text-align: left">UNINOVE - Colégio Técnico</div>       |       
                    <div class="col" style="text-align: right"><a @click="logOut()">Sair</a></div>
                </div>
            </div>
        </div> -->
        <!-- <navBottom></navBottom> -->
        <!-- <div class="alert alert-warning alert-dismissible fade show" role="alert" style="margin: 0px;">
            <strong>Está página está em teste! </strong>Esta é a nova visão da biblioteca uninove, se você encontrar algum erro, favor reportar no e-mail: <strong>biblioteca10@uninove.br</strong>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div> -->

        <!-- Bootstrap CSS -->
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-+0n0xVW2eSR5OomGNYDnhzAbDsOXxcvSN1TPprVMTNDbiYZCxYbOOl7+AMvyTG2x" crossorigin="anonymous">
        <!-- Bootstrap icon -->
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css">

        <!-- Navbar -->
        <!-- <navbar v-if="$route.path !== '/login'" :perfil="perfil" :userName="nome"></navbar> -->
        <navbar v-if="false" :perfil="perfil" :userName="nome"></navbar>

        <!-- Rotas são renderizadas aqui -->
        <router-view/>
        <rodape v-if="false"></rodape>

        <!-- Botão de logout od google -->
        <!-- <Btnlogout></Btnlogout> -->
    </div>
</template>

<script>
////@ is an alias to /src
    import navbar from '@/components/navbar.vue'
    // import navBottom from '@/components/navBottom.vue'
    // import Btnlogout from '@/components/logout.vue'
    import firebase from "firebase/app";
    import "firebase/analytics";
    import "firebase/auth";
    import rodape from '@/components/rodape.vue' 

export default {
    data() {
        return {
            modoDeCores: 'light',
            shouldRender: false,
            nome: '',
            perfil: '',
            timestamp: ''
        }
    },

    components: {
        // Btnlogout,
        navbar,
        // navBottom,
        rodape
    },

    // declaramos que antes do site ser montado, é necessário verificar se o usuário na sessão está logado E pertence ao domínio uni9
    beforeCreate() {
            // this.$router.replace({path: "/em-producao"})

            // if(localStorage.name){
            //     // console.log('USUARIO: ' + localStorage.name)
            //     this.shouldRender = true
            // } else {
            //     console.log('USUARIO NÃO AUTORIZADO')
            //     this.$router.replace({path: "/login"})
            // }

        

        // firebase.auth().onAuthStateChanged((user) => {
        //     if (!user){
        //         console.log('Usuário não logado');
        //         this.shouldRender = false;
        //     } else {
        //         if (!user.email.endsWith('@uni9.edu.br')){
        //             console.log("Usuário não está usando email institucional. Fazendo logOff e redirecionando....");
        //             this.shouldRender = false;

        //             // Função para fazer logOFF do usuário via autenticação google
        //             firebase.auth().signOut().then(() => {
        //                 alert('Usuário não está usando email institucional. Fazendo logOff e redirecionando....')
        //             }).catch(err => {
        //                 console.log(err)
        //             })
                    

        //         } else {
        //             this.shouldRender = true;
        //             console.log("User name: " + user.displayName);
        //             console.log("User name: " + user.email);
        //             console.log("User photo: " + user.photoURL)
                    
        //         }
                
        //     }

        // })
    },
    mounted(){
        this.nome = ''
        this.perfil = ''
        if(localStorage.name){
            this.nome = localStorage.name
            this.perfil = localStorage.perfil
            console.log(this.nome)
        }
    },
 
    methods: {
        toggle() {
            if (this.modoDeCores === "dark") {
                this.modoDeCores = "light"
                console.log("Modo Claro")
                document.documentElement.setAttribute('data-theme', ""); // atualiza para null o atribudo data-theme que fica bem no começo do html
                localStorage.setItem('theme', ""); // Salva para uma próxima sessão
            } else {
                this.modoDeCores = "dark"
                console.log("Modo Escuro")
                document.documentElement.setAttribute('data-theme', "darkMode"); // atualiza para "darkMode" o atribudo data-theme que fica bem no começo do html
                localStorage.setItem('theme', "darkMode"); // Salva para uma próxima sessão
            }
        },

        logOut(){
            localStorage.removeItem('name')
            localStorage.removeItem('timestamp')
            localStorage.removeItem('perfil')
            this.$router.replace({path: "/login"})
        },

        loginFirebase() {
            // Redirecionando para fazer login
            const provider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithRedirect(provider).then(() => {
                alert('Usuário logado')
                this.$router.replace({path: "/home"})
            }).catch(err => {
                console.log(err)
            })
        }
    }
}
</script>

<style>
    /* http://meyerweb.com/eric/tools/css/reset/ 
        v2.0 | 20110126
        License: none (public domain)
    */

    /* html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    } */
    /* HTML5 display-role reset for older browsers */
    /* article, aside, details, figcaption, figure, 
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
        line-height: 1;
    }
    ol, ul {
        list-style: auto;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    } */




:root {
  /* Variables for default theme (light-mode) */
    --fundo: #fff;
    --fundo-primo: #E9E9E9;  /*#F8F9FA*/
    --primary-nav: #fff;
    --border-nav: #dee2e6;
    --fontes: #2c3e50;
    --shadow: rgb(104, 104, 104, 0.5);
    --hero: #31abbd;
}

[data-theme="darkMode"] {
    /* Variables for dark mode */
    --fundo: #1D1E22;
    --primary-nav: #000;
    --border-nav: #3d4246;
    --fontes: #F3F3F3;
    --shadow: #1D1E22;
    --hero: #8277FF;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--fontes);
  background-color: var(--fundo);
  /* transition: all 0.3s ease-in-out; */
}



</style>
