import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import firebase from "firebase/app";
import "firebase/analytics";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueSplide from '@splidejs/vue-splide';

import "vue-easytable/libs/theme-default/index.css"; // import VueEasytable style
import VueEasytable from "vue-easytable"; // import VueEasytable library

Vue.use(VueSplide);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueEasytable);

import { rtdbPlugin } from 'vuefire'

Vue.use(rtdbPlugin)

const firebaseConfig = {
  apiKey: "AIzaSyBlNxp6bV-Ypr6o1BZC_V0J326iwAJDqUw",
  authDomain: "biblioteca-vue-24518.firebaseapp.com",
  projectId: "biblioteca-vue-24518",
  storageBucket: "biblioteca-vue-24518.appspot.com",
  messagingSenderId: "59882409262",
  appId: "1:59882409262:web:526c27c1a36d0d2a0cbebf",
  measurementId: "G-ZQXVGNCBTZ"
};

//Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  


Vue.config.productionTip = false

Vue.use(VueAwesomeSwiper, /* { default options with global component } */)

new Vue({
  mode: 'history',
  router,
  render: h => h(App)
}).$mount('#app')
